<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">Featured Content List</div>
						</div>
						<div class="flex flex-grow-1 align-items-center justify-content-end">
							<Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</div>
					<Divider></Divider>
                    <div class="grid">
                        <div class="col-12 lg:col-3 md:col-4">
							<div class="field" >
								<label for="type">Type</label>
								<Dropdown
									class="w-full"
									id="type"
                                    :options="types"
                                    optionLabel="name"
                                    optionValue="value"
									v-model="QueryType"
									placeholder="Select content Type"
								/>
							</div>
                        </div>
                        <div class="col-12 lg:col-3 md:col-4">
							<div class="field">
								<label for="QueryAgency">Agency</label>
								<Dropdown
									class="w-full"
									id="QueryAgency"
									:filter="true"
									:options="agencies"
									optionLabel="name"
									optionValue="id"
									v-model="QueryAgency"
									placeholder="Select agency"
								/>
							</div>
						</div>
                        <div class="col-12 lg:col-3 md:col-4">
							<div class="field">
								<label for="QueryStatus">Status</label>
								<Dropdown
									class="w-full"
									id="QueryStatus"
                                    :options="statusOptions"
                                    optionLabel="name"
                                    optionValue="value"
									v-model="QueryStatus"
									placeholder="Select status"
								/>
							</div>
						</div>
						<div class="col-12 lg:col-3 md:col-4 lg:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DataTable v-if="featuredList.data != ''" ref="dt" :value="featuredList.data" dataKey="id" responsiveLayout="scroll">
					<Column field="id" header="ID" headerStyle="width:4%; min-width:4rem;">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							#00{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="title" header="Name" headerStyle="width:12%; min-width:12rem;">
						<template #body="slotProps">
							<span v-if="slotProps.data.tour">{{slotProps.data.tour.title}}</span>
							<span v-if="slotProps.data.agency">{{slotProps.data.agency.name}}</span>
						</template>
					</Column>
					<Column field="title" header="Type" headerStyle="width:12%; min-width:10rem;">
						<template #body="slotProps">
							<span v-if="slotProps.data.tour">Tour</span>
							<span v-if="slotProps.data.agency">Agency</span>
						</template>
					</Column>
					<Column field="start_date" header="Start Date" headerStyle="width:24%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Start Date</span>
							{{formatTime(slotProps.data.start_date)}}
						</template>
					</Column>
					<Column field="end_date" header="End Date" headerStyle="width:24%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">End Date</span>
							{{formatTime(slotProps.data.end_date)}}
						</template>
					</Column>
					<Column field="status" header="Status" headerStyle="width:12%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Status</span>
							{{(slotProps.data.status)}}
						</template>
					</Column>
					<Column header="Actions" headerStyle="width:12%; min-width:11rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
							<Button icon="pi pi-pencil" class="p-button-raised p-button-text p-button-rounded mr-2" @click="editOpen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-raised p-button-text p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="featuredList.from" :rows="paginationValue" :totalRecords="featuredList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No featured content found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="itemDialog" :style="{width: '550px'}" :header="Header" :modal="true" class="p-fluid" :closable="false">
                    <div class="field">
						<label for="type">Type</label>
						<Dropdown
                            id="type"
							v-model="valid$.type.$model"
                            :options="types"
                            optionLabel="name"
                            optionValue="value"
                            placeholder="Select content type"
							:class="{'p-invalid': valid$.type.$invalid && submitted, 'p-disabled':selectedItem}"
						/>
						<span v-if="valid$.type.$error && submitted">
							<span
								id="type-error"
								v-for="(error, index) of valid$.type.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Type")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.type.$invalid && submitted) || valid$.type.$pending.$response"
							class="p-error"
						>
							{{ valid$.type.required.$message.replace("Value", "Type") }}
						</small>
					</div>

					<div class="field" v-if="valid$.type.$model == 'tour'">
                        <label for="tour_id">Tour<span>*</span></label>
                        <Dropdown
                            id="tour_id"
                            v-model="valid$.tour_id.$model"
                            :options="tours"
                            optionLabel="label"
                            optionValue="id"
							:filter="true"
                            placeholder="Select a tour"
                            :class="{'p-invalid': valid$.tour_id.$invalid && submitted, 'p-disabled':selectedItem}"
                        />
                        <span v-if="valid$.tour_id.$error && submitted">
                            <span
                                id="tour_id-error"
                                v-for="(error, index) of valid$.tour_id.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Tour Id")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.tour_id.$invalid && submitted) || valid$.tour_id.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.tour_id.required.$message.replace("Value", "Tour Id") }}
                        </small>
                    </div>

					<div class="field" v-if="valid$.type.$model == 'agency'">
                        <label for="agency_id">Agency<span>*</span></label>
                        <Dropdown
                            id="agency_id"
                            v-model="valid$.agency_id.$model"
                            :options="agencies"
                            optionLabel="name"
                            optionValue="id"
							:filter="true"
                            placeholder="Select an agency"
                            :class="{'p-invalid': valid$.agency_id.$invalid && submitted, 'p-disabled':selectedItem}"
                        />
                        <span v-if="valid$.agency_id.$error && submitted">
                            <span
                                id="agency_id-error"
                                v-for="(error, index) of valid$.agency_id.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Agency Id")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.agency_id.$invalid && submitted) || valid$.agency_id.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.agency_id.required.$message.replace("Value", "Agency Id") }}
                        </small>
                    </div>

					<div class="field">
						<label for="start_date">Start Date</label>
                        <Calendar
							:class="{'p-invalid': valid$.start_date.$invalid && submitted}"
                            class="w-full"
                            id="start_date"
                            v-model="valid$.start_date.$model"
                            dateFormat="dd-mm-yy"
                            :showButtonBar="true"
                            selectionMode="single"
                            :showIcon="true"
							:showTime="true"
							:showSeconds="true"
							hourFormat="12"
                            placeholder="Select a date"
                        />
						<span v-if="valid$.start_date.$error && submitted">
							<span
								id="start_date-error"
								v-for="(error, index) of valid$.start_date.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Start Date")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.start_date.$invalid && submitted) || valid$.start_date.$pending.$response"
							class="p-error"
						>
							{{ valid$.start_date.required.$message.replace("Value", "Start Date") }}
						</small>
					</div>

					<div class="field">
						<label for="end_date">End Date</label>
                        <Calendar
							:class="{'p-invalid': valid$.end_date.$invalid && submitted}"
                            class="w-full"
                            id="end_date"
                            v-model="valid$.end_date.$model"
                            dateFormat="dd-mm-yy"
                            :showButtonBar="true"
                            selectionMode="single"
                            :showIcon="true"
							:showTime="true"
							:showSeconds="true"
							hourFormat="12"
                            placeholder="Select a date"
                        />
						<span v-if="valid$.end_date.$error && submitted">
							<span
								id="end_date-error"
								v-for="(error, index) of valid$.end_date.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "End Date")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.end_date.$invalid && submitted) || valid$.end_date.$pending.$response"
							class="p-error"
						>
							{{ valid$.end_date.required.$message.replace("Value", "End Date") }}
						</small>
					</div>

					<div class="field">
						<label for="status">Status</label>
                        <Dropdown
                            id="status"
							v-model="valid$.status.$model"
                            :options="statusOptions"
                            optionLabel="name"
                            optionValue="value"
                            placeholder="Select content status"
							:class="{'p-invalid': valid$.status.$invalid && submitted}"
						/>
						<span v-if="valid$.status.$error && submitted">
							<span
								id="status-error"
								v-for="(error, index) of valid$.status.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Status")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.status.$invalid && submitted) || valid$.status.$pending.$response"
							class="p-error"
						>
							{{ valid$.status.required.$message.replace("Value", "Status") }}
						</small>
					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button v-if="selectedItem != null" label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="Edit(!valid$.$invalid)" :loading="loading"/>
						<Button v-else label="Create" icon="pi pi-check" class="p-button p-button-raised" @click="saveFeaturedContent(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '550px'}" header="Confirm" :modal="true" :closable="false">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedItem">Are you sure you want to delete <b v-if="selectedItem.tour">{{selectedItem.tour.title}}</b><b v-if="selectedItem.agency">{{selectedItem.agency.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-raised p-button-danger" @click="Delete" />
					</template>
				</Dialog>

				<Dialog v-model:visible="showDialog" :style="{width: '550px'}" header="Featured Content Details" :modal="true" :closable="false">
					<div class="surface-section">
						<ul class="list-none p-0 m-0">
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">#00{{selectedItem.id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap" v-if="selectedItem.tour">
								<div class="text-500 w-8 md:w-3 font-medium">Tour Title</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.tour.title}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap" v-if="selectedItem.agency">
								<div class="text-500 w-8 md:w-3 font-medium">Agency Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.agency.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Start Date</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatTime(selectedItem.start_date)}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">End Date</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatTime(selectedItem.end_date)}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Status</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.status}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Created At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.created_at ? formatTime(selectedItem.created_at) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Updated At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.updated_at ? formatTime(selectedItem.updated_at) : '-'}}</div>
							</li>
						</ul>
					</div>
					<template #footer>
						<Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from 'vue';
import Axios from 'axios';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';

export default {
	setup() {
		const toast = useToast();
		const router = useRouter();
		const route = useRoute();

		const loading = ref(false);
		const pageLoading = ref(true);
		const itemDialog = ref(false);
		const deleteDialog = ref(false);
		const showDialog = ref(false);
		const submitted = ref(false);

		const featuredList = ref(null);
		const selectedItem = ref(null);
		const filters = ref({});
		const QueryType = ref();
        const QueryAgency = ref();
        const tours = ref();
        const agencies = ref();
        const QueryStatus = ref();
		const paginationValue = ref(10);
		const pageValue = ref();
		const Header = ref('Create Featured Content');

        const types = ref([
            {value: 'tour', name: 'Tour', key: 'M'},
            {value: 'agency', name: 'Agency', key: 'O'},
        ]);

        const statusOptions = ref([
            {value: 'Active', name: 'Active', key: 'M'},
            {value: 'Inactive', name: 'Inactive', key: 'O'},
        ]);

		const state = reactive({
            type: '',
            tour_id: '',
            agency_id: '',
            start_date: '',
            end_date: '',
            status: '',
		});

		const rules = {
            type: { required },
            tour_id: { required : requiredIf(
				function(){ return state.type == "tour"})
			},
            agency_id: { required : requiredIf(
				function(){ return state.type == "agency"})
			},
            start_date: { required },
            end_date: { required },
            status: { required },
		};

		const valid$ = useVuelidate (rules, state);

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.type != null){
                QueryType.value = route.query.type;
            }
            if(route.query.agencyId != null){
                QueryAgency.value = route.query.agencyId;
            }
            if(route.query.status != null){
                QueryStatus.value = route.query.status;
            }
        };
        getActiveQuerys();

		//FEATURED LIST
		const getFeaturedList = () =>{
			Axios
			.get("api/admin/featured-content/list",{
				params: {
                    page: pageValue.value,
                    pagination: paginationValue.value,
                    type: QueryType.value,
                    agencyId: QueryAgency.value,
                    status: QueryStatus.value,
                }
			}
			)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					featuredList.value = res.data.data;
					featuredList.value.from = featuredList.value.from-1;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getFeaturedList();

		const getTourList = () => {
			Axios
			.get("api/admin/tour/dropdown-list")
			.then((res) => {
				if (res.data.response == "success") {
					tours.value = res.data.data;
					for(let item of tours.value){
						item.label = '#00' + item.id + ' - ' + item.title;
					}
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
		};
		getTourList();

		const getQueryAgency = () => {
			Axios
			.get("api/admin/agency/dropdown-list")
			.then((res) => {
				if (res.data.response == "success") {
					agencies.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
		};
		getQueryAgency();


		//OTHER FUNCTIONS
		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };

		const openNew = () => {
			submitted.value = false;
			itemDialog.value = true;
		};

		const hideDialog = () => {
			itemDialog.value = false;
			showDialog.value = false;
			submitted.value = false;
            selectedItem.value = null;
            Header.value = 'Create Featured Content';

            state.type = '';
            state.tour_id = '';
            state.agency_id = '';
            state.start_date = '';
            state.end_date = '';
            state.status = '';
		};

		//CREATE
		const saveFeaturedContent = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

            formData1.append("type", state.type);
            if(state.tour_id)formData1.append("tour_id", parseInt(state.tour_id));
            if(state.agency_id)formData1.append("agency_id", parseInt(state.agency_id));
            formData1.append("start_date", moment(state.start_date).format("YYYY-MM-DD HH:mm:ss"));
            formData1.append("end_date", moment(state.end_date).format("YYYY-MM-DD HH:mm:ss"));
            formData1.append("status", state.status);

			Axios
            .post("api/admin/featured-content/create", formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					itemDialog.value = false;
					getFeaturedList();
					state.type = '';
					state.tour_id = '';
					state.agency_id = '';
					state.start_date = '';
					state.end_date = '';
					state.status = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                showToast("error", "Error", err.message);
            });
		};

		//READ
		const confirmDetailSelected = (product) => {
			selectedItem.value = product;
			showDialog.value = true;
		};
		
		//DELETE
		const confirmDelete = (product) => {
			selectedItem.value = product;
			deleteDialog.value = true;
		};

		const Delete = () => {
			Axios
			.delete("api/admin/featured-content/delete/"+selectedItem.value.id)
			.then(res => {
				if(res.data.response == "success"){
					showToast("success", "Success", res.data.message);
					getFeaturedList();
					deleteDialog.value = false;
				}
				else{
					showToast("error", "Error", res.data.message);
				}
			})
			.catch(err => {
				console.log(err);
			})
		};

		//EDIT OR UPDATE
		const editOpen = (product) => {
			selectedItem.value = product;
			itemDialog.value = true;
			Header.value = 'Edit Featured Content';

			if (selectedItem.value.agency) {
				selectedItem.value.type = 'agency';
			}else{
				selectedItem.value.type = 'tour';
			}

            state.type = selectedItem.value.type;
            state.tour_id = selectedItem.value.tour_id;
            state.agency_id = selectedItem.value.agency_id;
            state.start_date = new Date(selectedItem.value.start_date);
            state.end_date = new Date(selectedItem.value.end_date);
            state.status = selectedItem.value.status;
		};

		const Edit = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

            formData1.append("start_date", moment(state.start_date).format("YYYY-MM-DD HH:mm:ss"));
            formData1.append("end_date", moment(state.end_date).format("YYYY-MM-DD HH:mm:ss"));
            formData1.append("status", state.status);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/admin/featured-content/update/${selectedItem.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
                    selectedItem.value = null;
					itemDialog.value = false;
					getFeaturedList();
					state.type = '';
					state.tour_id = '';
					state.agency_id = '';
					state.start_date = '';
					state.end_date = '';
					state.status = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                showToast("error", "Error", err.message);
                console.log(err);
            });
		};

		//FILTER
		const getFilteredList = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(QueryType.value != null){
                queryParams.type = QueryType.value;
            }
            if(QueryAgency.value != null){
                queryParams.agencyId = QueryAgency.value;
            }
            if(QueryStatus.value != null){
                queryParams.status = QueryStatus.value;
            }

            router.replace({ name: 'featured', query: { ...queryParams } });

            getFeaturedList();
        };

        const clearFilters = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            QueryType.value = null;
            QueryAgency.value = null;
            QueryStatus.value = null;
            router.replace();

            getFeaturedList();
        };
		
		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'featured', query: { ...queryParams } });

            getFeaturedList();
		}

		return{
			pageLoading,
			valid$,
			itemDialog,
			deleteDialog,
			showDialog,
			selectedItem,
			filters,
			submitted,
			featuredList,
			saveFeaturedContent,
			loading,
			formatDate,
			formatTime,
			confirmDelete,
			Delete,
			editOpen,
			Edit,
			openNew,
			hideDialog,
			QueryType,
            QueryAgency,
            QueryStatus,
			paginationValue,
			clearFilters,
			getFilteredList,
			confirmDetailSelected,
			onPage,
			Header,
            types,
            statusOptions,
			tours,
			agencies
			,state
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
